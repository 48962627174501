.Embeded-container {
    height: 90vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.6); /* Adjust the opacity as needed */
    backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
    border-radius: 10px; /* Optional: Add rounded corners for a better effect */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Optional: Add padding for content inside the container */
  }
  